<template>
  <div class="lineDiv" ref="lineChar"></div>
</template>

<script>
/*
 * 传x轴(日期)，默认为空
 * 传Y轴（数据），默认为空
 * */

import * as echarts from "echarts/core";
import { LineChart } from "echarts/charts";
import { TooltipComponent, GridComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([LineChart, TooltipComponent, GridComponent, CanvasRenderer]);
//对Proxy对象进行拆箱。
const unwarp = (obj) => obj && (obj.__v_raw || obj.valueOf() || obj);
import { nextTick } from "vue";

export default {
  name: "line-bar",
  data() {
    return {
      myChart: "",
    };
  },
  props: {
    xAxis: {
      type: Object,
      default() {
        return {
          type: "category",
          data: [201903, 201904, 201905],
        };
      },
    },
    color: {
      type: Array,
      default() {
        return ["#113491", "#bfd9ef", "#65a6ea"];
      },
    },
    series: {
      type: Array,
      default() {
        return [
          {
            type: "line",
            name: "全体网民触达",
            data: [73079874, 73584250, 55022549],
          },
        ];
      },
    },
  },
  mounted() {
    nextTick(() => {
      this.myChart = echarts.init(this.$refs.lineChar);
      this.resize();
      // this.drawLineBar();
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", () => {
      clearTimeout(this.timer);
      this.myChart.dispose();
    });
  },
  methods: {
    drawLineBar() {
      unwarp(this.myChart).setOption({
        grid: {
          show: false,
          top: 30,
          right: 20,
          bottom: 20,
          left: 30,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
        },
        xAxis: Object.assign(
          {
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
            },
            splitLine: {
              show: false,
            },
          },
          this.xAxis
        ),
        yAxis: {
          type: "value",
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
          },
          splitLine: {
            show: false,
          },
        },
        series: this.series,
        textStyle: {
          fontFamily: "Microsoft YaHei",
          color: "#111",
        },
        color: this.color,
      });
    },
    resize() {
      this.myChart.resize();
      window.addEventListener("resize", () => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.myChart.resize();
        }, 500);
      });
    },
  },
  watch: {
    series: {
      handler() {
        this.drawLineBar();
      },
      deep: true,
    },
    xAxis: {
      handler() {
        this.drawLineBar();
      },
      deep: true,
    },
    color: {
      handler() {
        this.drawLineBar();
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.lineDiv {
  flex: 1;
  width: 100%;
  height: 100%;
}
</style>
