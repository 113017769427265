<template>
  <div>
    <span
      v-for="(item, index) in legendData"
      :key="index"
      style="font-size: 12px; color: #666; margin-left: 20px"
    >
      <i
        style="
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          border: 1px solid #eee;
        "
        :style="{ backgroundColor: legendColor[index] }"
      ></i>
      {{ item }}
    </span>
  </div>
</template>

<script>
export default {
  name: "legends",
  props: {
    legendData: {
      type: Array,
      default() {
        return ["渗透率"];
      },
    },
    legendColor: {
      type: Array,
      default() {
        return ["#51a6f0"];
      },
    },
  },
};
</script>

<style scoped></style>
