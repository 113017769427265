<template>
  <div class="homePageIndexWrap">
    <el-card style="margin-bottom: 21px; border-radius: 10px">
      <h2 class="insight_title">社媒平台概况</h2>
      <div class="midDiv line">
        <p class="title" style="padding-bottom: 5px">
          <span style="font-size: 16px">平台流量</span>
          <legends
            :legend-color="articleColor"
            :legend-data="articleTrend.legend.data"
          />
        </p>
        <line-bar
          :color="articleColor"
          :series="articleTrend.series"
          :xAxis="articleTrend.xAxis"
        />
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-top: 15px;
        "
      >
        <stagger-x-bar
          style="width: 47%; height: 260px; margin-bottom: 50px"
          v-for="(item, i) in profileData"
          :ref="'staggerXBar' + i"
          :key="item.title"
          :title="item.title"
          :xAxisData="item.xAxisData"
          :yAxisData="item.yAxisData"
          :seriesData="item.seriesData"
          :color="
            item.title === '性别占比'
              ? ['rgb(83, 186, 210)', '#e893a8']
              : item.title === '城市级别占比'
              ? ['#32a3be', 'rgb(83, 186, 210)', '#e893a8', '#e37893']
              : [
                  '#2c7d98',
                  '#32a3be',
                  'rgb(83, 186, 210)',
                  '#d1eef8',
                  '#f0a3b6',
                  '#e37893',
                  '#a02947',
                ]
          "
        />
      </div>
    </el-card>
    <el-card style="margin-bottom: 21px; border-radius: 10px">
      <h2 class="insight_title">声量榜单</h2>
      <div
        style="display: flex; justify-content: space-between; flex-wrap: wrap"
      >
        <div class="tableMain" style="width: 47%">
          <el-table
            ref="multipleTable"
            v-loading="brandListTableLoading"
            :data="tableData"
            @sort-change="sortChange"
            :default-sort="{ prop: 'exposure', order: 'descending' }"
          >
            <el-table-column
              prop="number"
              align="center"
              label="排名"
              width="55"
            >
            </el-table-column>
            <el-table-column prop="name" label="品牌" align="center">
              <template #default="{ row }">
                <span style="color: rgb(81, 166, 240)">{{ row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="exposure" label="曝光量" align="center">
              <template #default="scope">
                {{ sliceDecimal(scope.row.exposure) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="pgcPostNum"
              label="PGC发帖数量"
              align="center"
              width="180"
            >
              <template #default="scope">
                {{ sliceDecimal(scope.row.pgcPostNum) }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="tableMain" style="width: 47%">
          <el-table
            ref="multipleTable"
            v-loading="productListTableLoading"
            :data="tableDataPriduct"
            @sort-change="productSortChange"
            :default-sort="{ prop: 'exposure', order: 'descending' }"
            row-key="id"
          >
            <el-table-column
              prop="number"
              align="center"
              label="排名"
              width="55"
            >
            </el-table-column>
            <el-table-column prop="name" label="产品" align="center">
              <template #default="{ row }">
                <span style="color: rgb(81, 166, 240)">{{ row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="exposure" label="曝光量" align="center">
              <template #header>
                <span>曝光量</span>
              </template>
              <template #default="scope">
                {{ sliceDecimal(scope.row.exposure) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="pgcPostNum"
              label="PGC发帖数量"
              align="center"
              width="180"
            >
              <template #default="scope">
                {{ sliceDecimal(scope.row.pgcPostNum) }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-card>
    <el-card style="margin-bottom: 21px; border-radius: 10px">
      <h2 class="insight_title">热点内容</h2>
      <el-radio-group v-model="radio">
        <el-radio label="all">全部</el-radio>
        <el-radio label="wechat">微信</el-radio>
        <el-radio label="weibo">微博</el-radio>
        <el-radio label="douyin">抖音</el-radio>
        <el-radio label="xiaohongshu">小红书</el-radio>
        <el-radio label="bilibili">哔哩哔哩</el-radio>
      </el-radio-group>
      <div>
        <div class="kolContentTable" style="margin-top: 10px">
          <el-table
            v-loading="articleTopLoading"
            :data="kolContentTableDataCopy"
          >
            <el-table-column
              prop="brandName"
              align="center"
              width="100"
              label="品牌"
            >
            </el-table-column>
            <el-table-column
              prop="productName"
              align="center"
              show-overflow-tooltip
              label="产品"
            >
              <template #default="{ row }">
                {{
                  row.productName === null
                    ? "--"
                    : `${row.productName.replace(/,/g, "\n")}`
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="kolName"
              align="center"
              width="100"
              show-overflow-tooltip
              label="kol名称"
            >
            </el-table-column>
            <el-table-column
              prop="platform"
              align="center"
              width="130"
              label="平台"
            >
              <template #default="{ row }">
                {{ platformToZn(row.platform) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="kolFans"
              align="center"
              width="120"
              label="粉丝量"
            >
              <template #default="{ row }">
                {{ sliceDecimal(row.kolFans) }}
              </template>
            </el-table-column>
            <el-table-column prop="postDate" align="center" label="发文日期">
            </el-table-column>
            <el-table-column
              prop="link"
              align="center"
              show-overflow-tooltip
              label="文章链接"
            >
              <template #default="{ row }">
                <a target="_blank" style="color: #51a6f0" :href="row.link">{{
                  row.link
                }}</a>
              </template>
            </el-table-column>
            <el-table-column prop="exposure" align="center" label="阅读量">
              <template #default="{ row }">
                {{ sliceDecimal(row.exposure) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="engagement"
              align="center"
              width="120"
              label="互动量"
            >
              <template #default="{ row }">
                {{ sliceDecimal(row.engagement) }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import lineBar from "@/components/echarts/lineBar";
import legends from "@/views/KolDetail/legends";
import staggerXBar from "@/components/echarts/staggerXBar";
import {
  getHomeTrend,
  getHomePersona,
  getHomeSovBrandRankinglist,
  getHomeSovProductRankinglist,
  getHomeSovArticleTop,
} from "@/api/homepage/home";

export default {
  data() {
    return {
      articleColor: [
        "#2c7d98",
        "#32a3be",
        "#d1eef8",
        "#f0a3b6",
        "#e37893",
        "#a02947",
      ],
      articleTrend: {
        xAxis: {},
        series: [],
        legend: {
          data: [],
        },
      },
      monthTrend: {
        xAxis: {
          type: "category",
          data: [201903, 201904, 201905, 201906],
        },
        series: [
          {
            type: "line",
            name: "全体网民触达",
            data: [73079874, 73584250, 55022549],
          },
        ],
        legend: {},
      },
      profileData: [],
      tableData: [
        {
          number: 1,
          brand: "兰蔻",
          exposure: 123123,
          pgcPostNum: 123,
        },
        {
          number: 2,
          brand: "雅诗兰黛",
          exposure: 1223,
          pgcPostNum: 123,
        },
        {
          number: 3,
          brand: "欧莱雅",
          exposure: 123123,
          pgcPostNum: 123,
        },
        {
          number: 4,
          brand: "海蓝之谜",
          exposure: 123123,
          pgcPostNum: 123,
        },
        {
          number: 5,
          brand: "魅可",
          exposure: 123123,
          pgcPostNum: 123,
        },
      ],
      tableDataPriduct: [
        {
          number: 1,
          brand: "兰蔻发光眼霜",
          exposure: 123123,
          pgcPostNum: 1234,
        },
        {
          number: 2,
          brand: "滋色隔离霜",
          exposure: 1223,
          pgcPostNum: 123,
        },
        {
          number: 3,
          brand: "海蓝之谜精华面霜",
          exposure: 123123,
          pgcPostNum: 123,
        },
        {
          number: 4,
          brand: "润百颜屏障调理次抛",
          exposure: 123123,
          pgcPostNum: 123,
        },
        {
          number: 5,
          brand: "娇兰复原蜜",
          exposure: 123123,
          pgcPostNum: 123,
        },
      ],
      kolContentTableData: [],
      kolContentTableDataCopy: [],
      radio: "all",
      brandListTableLoading: false,
      productListTableLoading: false,
      articleTopLoading: false,
      sortColumn: "exposure",
      productSortColumn: "exposure",
      productSortDirection: "desc",
      sortDirection: "desc",
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.getHomeTrend();
      this.getHomePersona();
      this.getHomeSovBrandRankinglist();
      this.getHomeSovProductRankinglist();
      this.getHomeSovArticleTop();
    },
    async getHomeTrend() {
      let { code, data } = await getHomeTrend();
      if (code === 0) {
        let temp = [];
        let tempArr = [];
        data[0].trend.forEach((v) => {
          temp.push(v.xAxis - 0);
        });
        this.articleTrend.xAxis.data = temp;
        data.forEach((v) => {
          this.articleTrend.legend.data.push(this.platformToZn(v.name));
          v.trend.forEach((j) => {
            tempArr.push(j.yAxis);
          });
          this.articleTrend.series.push({
            data: tempArr,
            name: this.platformToZn(v.name),
            type: "line",
          });
          tempArr = [];
        });
        this.articleTrend.series.forEach((item) => {
          for (let i = 0; i < item.data.length; i++) {
            if (item.data[i] === null) {
              item.data[i] = 0;
            }
          }
        });
      }
    },
    async getHomePersona() {
      let { code, data } = await getHomePersona();
      const yAxisData = [
        "wechat",
        "weibo",
        "xiaohongshu",
        "douyin",
        "bilibili",
        "kuaishou",
      ];
      this.profileData = data.map((item) => {
        const arr = [];
        const temp = {
          yAxisData,
        };
        switch (item.name) {
          case "age":
            temp.title = "年龄占比";
            break;
          case "gender":
            temp.title = "性别占比";
            break;
          case "income":
            temp.title = "收入水平占比";
            break;
          case "city_tier":
            temp.title = "城市级别占比";
            break;
        }
        temp.xAxisData = item.profile.map((temp) => temp.label);
        const keyArr = item.profile.map((temp) => temp.id);
        yAxisData.forEach((platform) => {
          const temp = [];
          keyArr.forEach((key) => {
            temp.push(
              item.platformPersona[platform] &&
                item.platformPersona[platform][key]
                ? Math.floor(item.platformPersona[platform][key] * 100)
                : null
            );
          });
          const index = temp.findIndex((item) => item != null);
          if (index !== -1) {
            // 更改temp的最后一个值以保证temp每项相加和为100
            let endValue = 100;
            temp.slice(0, -1).forEach((item) => {
              endValue = endValue - item;
            });
            temp[temp.length - 1] = endValue <= 0 ? 0 : endValue;
          }
          arr.push(temp);
        });
        // 转置
        temp.seriesData = arr[0].map((col, i) => arr.map((row) => row[i]));
        return temp;
      });
      console.log(code, data);
    },
    async getHomeSovBrandRankinglist() {
      this.brandListTableLoading = true;
      let { code, data } = await getHomeSovBrandRankinglist({
        sortColumn: this.sortColumn,
        sortDirection: this.sortDirection === "ascending" ? "asc" : "desc",
      });
      if (code === 0) {
        this.formatNull(data);
        data.forEach((v, i) => {
          v.number = i + 1;
        });
        this.tableData = data;
        this.brandListTableLoading = false;
      }
    },
    async getHomeSovProductRankinglist() {
      this.productListTableLoading = true;
      let { code, data } = await getHomeSovProductRankinglist({
        sortColumn: this.productSortColumn,
        sortDirection:
          this.productSortDirection === "ascending" ? "asc" : "desc",
      });
      if (code === 0) {
        this.formatNull(data);
        data.forEach((v, i) => {
          v.number = i + 1;
        });
        this.tableDataPriduct = data;
        this.productListTableLoading = false;
      }
    },
    async getHomeSovArticleTop() {
      this.articleTopLoading = true;
      let { code, data } = await getHomeSovArticleTop({
        platform: this.radio,
        sortColumn: "exposure",
        sortDirection: "desc",
      });
      if (code === 0) {
        console.log(data);
        this.kolContentTableDataCopy = data;
        this.articleTopLoading = false;
      }
    },
    sortChange(v) {
      this.sortColumn = v.prop;
      this.sortDirection = v.order;
      this.getHomeSovBrandRankinglist();
    },
    productSortChange(v) {
      this.productSortColumn = v.prop;
      this.productSortDirection = v.order;
      this.getHomeSovProductRankinglist();
    },
  },
  watch: {
    radio() {
      this.getHomeSovArticleTop();
    },
  },
  components: {
    lineBar,
    staggerXBar,
    legends,
  },
};
</script>

<style lang="scss" scoped>
.homePageIndexWrap {
  .insight_title {
    font-size: 20px;
    margin-bottom: 20px;
    color: #4f4f4f;
  }

  .title {
    font-weight: normal;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    font-size: $font-size-medium-x;
    border-bottom: 1px solid $color-title-underline;
    display: flex;
    align-items: center;
  }

  .tableMain {
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    border-radius: 15px !important;
    overflow: hidden;
  }

  .midDiv {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #fff;
  }

  .line {
    width: 100%;
    flex: none;
    height: 300px;
  }

  .kolContentTable {
    .el-input--suffix .el-input__inner {
      height: 30px;
    }

    .el-input__suffix {
      top: 5px;
    }

    .el-select .el-input__suffix {
      top: 0;
    }

    .el-input--suffix .el-input__icon {
      line-height: 30px;
    }
  }
}
</style>

<style lang="scss">
.homePageIndexWrap {
  .el-dialog {
    height: 414px !important;
  }

  .el-dialog__body {
    height: 434px;
  }
}
</style>
