import request from "@/utils/axios";

//  /home/trend 平台流量趋势
export function getHomeTrend(params) {
  return request.get("/home/trend", params);
}

// /home/persona 平台人群画像
export function getHomePersona(params) {
  return request.get("/home/persona", params);
}

// /home/sov/brand/rankinglist 声量品牌榜单
export function getHomeSovBrandRankinglist(params) {
  return request.get("/home/sov/brand/rankinglist", params);
}

// /home/sov/product/rankinglist 声量产品榜单
export function getHomeSovProductRankinglist(params) {
  return request.get("/home/sov/product/rankinglist", params);
}

//  /home/sov/article/top 投放内容Top10
export function getHomeSovArticleTop(params) {
  return request.get("/home/sov/article/top", params);
}
